import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

Vue.filter('NumberFormat', function (value) {
  if (!value) {
    return '0'
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1-') // 将整数部分逢三一断
  return intPartFormat
})

Vue.filter('dayjs', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

Vue.filter('moment', function (dataStr, pattern = 'YYYY/MM/DD HH:mm') {
  return moment(dataStr).format(pattern)
})

Vue.filter('isEmptyObject', function (value) {
  return value === undefined || JSON.stringify(value) === '{}'
})

Vue.filter('devName', function (name) {
  const e = /(.*)(\(.{4}:.{4}\))$/
  const res = name.replace(e, function (all, key, address) {
    return key
  })
  return res
})

Vue.filter('audioStatuTxt', function (status) {
  const stateMap = {
    1: 'Mute',
    2: 'Unmute',
    3: 'Raise your hand',
    4: 'Put down',
    5: 'No Audio'
  }
  return stateMap[status]
})

Vue.filter('vedioStatuTxt', function (status) {
  const stateMap = {
    1: 'Stop Video',
    2: 'Start Video',
    3: 'Raise your hand',
    4: 'Put down',
    5: 'No Video'
  }
  return stateMap[status]
})
