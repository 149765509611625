import storage from 'store'
import { objToStrMap, strMapToObj } from '@/utils/util'
const user = {
  state: {
    localUser: {},
    localPubAudio: false, // 本地广播音频状态
    audioStatus: 4, // 本地麦克风状态 1 正在语音 2 关闭语音状态  3 关闭语音 无权限需举手状态  4 举手待放下手状态 5 无麦克风
    localPubVideo: false, // 本地广播视频状态
    videoStatus: 4, // 本地摄像头状态 1 已打开视频 2 关闭视频状态  3 关闭视频 无权限需举手状态 4 举手待放下手状态 5 无摄像头
    groupUserList: {}, // 用户群组
    userListShow: false, // 用户列表显示状态
    inviteShow: false, // 邀请参会人弹框
  },

  mutations: {
    SET_INVITE_SHOW: (state, bool) => {
      state.inviteShow = bool
    },
    SET_USER_LIST_SHOW: (state, bool) => {
      state.userListShow = bool
    },
    SET_LOCALUSER: (state, info) => {
      state.localUser = { ...state.localUser, ...info }
    },
    TOOGEL_LOCALPUBAUDIO: (state, bool) => {
      state.localPubAudio = bool
    },
    TOOGLE_LOCALPUBVIDEO: (state, bool) => {
      state.localPubVideo = bool
    },
    SET_AUDIOSTATUS: (state, sta) => {
      state.audioStatus = sta
    },
    SET_VIDEOSTATUS: (state, sta) => {
      state.videoStatus = sta
    },
    SET_GROUPINFO: (state, info) => {
      state.groupUserList = info
    },
    UPDATE_GROUPINFO: (state, data) => {
      // console.log('udate groupuser-----------------------------')
      const groupUserMap = objToStrMap(state.groupUserList)
      const user = Object.assign({}, groupUserMap.get(data.UserID), data)
      groupUserMap.set(data.UserID, user)
      state.groupUserList = strMapToObj(groupUserMap)
    },
    DELETE_GROUPINFO: (state, key) => {
      const groupUserMap = objToStrMap(state.groupUserList)
      groupUserMap.delete(key + '')
      state.groupUserList = strMapToObj(groupUserMap)
    },
    CLEAR_GROUPINFO: state => {
      // 清空用户
      state.groupUserList = {}
    },
    CLEAR_LOCAL_USER: state => {
      state.localUser = {}
    }
  },

  actions: {
    SetLocalUser ({ commit }, info) {
      // 设置本地用户信息
      commit('SET_LOCALUSER', info)
      storage.set('localuser', info)
    },
    SetLocalPubAudio ({ commit }, bool) {
      // 设置广播音频状态
      commit('TOOGEL_LOCALPUBAUDIO', bool)
    },
    SetAudioStatus ({ commit }, status) {
      // 设置麦克风的状态
      commit('SET_AUDIOSTATUS', status)
    },
    SetLocalPubVideo ({ commit }, bool) {
      // 设置广播视频状态
      commit('TOOGLE_LOCALPUBVIDEO', bool)
    },
    SetVideoStatus ({ commit }, status) {
      // 设置摄像头的状态
      commit('SET_VIDEOSTATUS', status)
    },
    SetGroupInfo ({ commit }, info) {
      // 设置组成员信息
      commit('SET_GROUPINFO', info)
    },
    UpdateGroupInfo ({ commit }, data) {
      // 增加or修改用户
      commit('UPDATE_GROUPINFO', data)
    },
    DeleteGroupInfo ({ commit }, data) {
      // 删除用户
      commit('DELETE_GROUPINFO', data)
    },
    ClearGroupInfo ({ commit }, data) {
      // 清空用户
      commit('DELETE_GROUPINFO', data)
    }
  }
}

export default user
