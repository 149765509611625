import Vue from 'vue'

// base library
import {
  ConfigProvider,
  Input,
  Button,
  Radio,
  Checkbox,
  Select,
  Switch,
  Form,
  Modal,
  Icon,
  Popover,
  Dropdown,
  Tooltip,
  message,
  notification,
  FormModel,
  Tabs,
  Menu,
  Spin,
  Tree
} from 'ant-design-vue'

// ext library
import VueClipboard from 'vue-clipboard2'
import VueCropper from 'vue-cropper'
import Dialog from '@/components/Dialog'
import svgIcon from '@/components/svg-icon'
import Toast from '../components/Toast'
import PermissionHelper from '@/core/permission/permission'
import './directives/action'

Vue.use(ConfigProvider)
Vue.use(Input)
Vue.use(Button)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(Switch)
Vue.use(Select)
Vue.use(Form)
Vue.use(Modal)
Vue.use(Icon)
Vue.use(Popover)
Vue.use(Dropdown)
Vue.use(Tooltip)
// Vue.use(Toast)
Vue.use(Toast)
Vue.use(FormModel)
Vue.use(Tabs)
Vue.use(Menu)
Vue.use(Spin)
Vue.use(Tree)
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning
// Vue.prototype.$toast = Toast
Vue.use(Dialog) // this.$dialog func

Vue.use(PermissionHelper)
Vue.use(VueCropper)
Vue.use(VueClipboard)
Vue.component('svg-icon', svgIcon)

process.env.NODE_ENV !== 'production' && console.warn('[antd-pro] NOTICE: Antd use lazy-load.')
