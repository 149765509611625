import storage from 'store'

const devices = {
  state: {
    preOpenAudio: storage.get('preOpenAudio') || 0,
    preOpenVideo: storage.get('preOpenVideo') || 0,
    isCameraStop: false, // 摄像头是否可以关闭
    isMicStop: false, // 麦克风是否可以关闭
    currentMic: '' || storage.get('currentMic'),
    micDevList: [], // 麦克风
    currentSpk: '' || storage.get('currentSpk'), // 当前扬声器
    spkDevList: [], // 扬声器列表
    currentCam: '' || storage.get('currentCam'), // 当前摄像头
    camDevList: [], // 摄像头列表
    currentResolution: '' || storage.get('currentResolution'), // 当前分辨率
    resolutionList: [] // 分辨率列表
  },
  mutations: {
    SET_PREOPENAUDIO: (state, pre) => {
      // 当前麦克风
      state.preOpenAudio = pre
      storage.set('preOpenAudio', pre)
    },
    SET_PREOPENVIDEO: (state, pre) => {
      // 当前麦克风
      state.preOpenVideo = pre
      storage.set('preOpenVideo', pre)
    },
    SET_CAMERASTOP: (state, bool) => {
      // 设置摄像头是否可以关闭
      state.isCameraStop = bool
    },
    SET_MICSOTP: (state, bool) => {
      // 设置麦克风是否可以关闭
      state.isMicStop = bool
    },
    SET_CURRENTMIC: (state, cur) => {
      // 当前麦克风
      state.currentMic = cur
      storage.set('currentMic', cur)
    },
    SET_MICDEVLIST: (state, Arr) => {
      state.micDevList = Arr
    },
    SET_CURRENTSPK: (state, cur) => {
      // 当前扬声器
      state.currentSpk = cur
      storage.set('currentSpk', cur)
    },
    SET_SPKDEVLIST: (state, Arr) => {
      state.spkDevList = Arr
    },
    SET_CURRENTCAM: (state, cur) => {
      // 当前摄像头
      state.currentCam = cur
      storage.set('currentCam', cur)
    },
    SET_CAMDEVLIST: (state, Arr) => {
      state.camDevList = Arr
    },
    SET_RESOLUTION: (state, cur) => {
      // 当前分辨率
      state.currentResolution = cur
      storage.set('currentResolution', cur)
    },
    SET_RESOLUTION_LIST: (state, Arr) => {
      state.resolutionList = Arr
    }
  },
  actions: {
    setPreOpenAudio({ commit }, pre) {
      commit('SET_PREOPENAUDIO', pre)
    },
    // 设置麦克风
    setPreOpenVideo({ commit }, pre) {
      commit('SET_PREOPENVIDEO', pre)
    },
    setCameraStop({ commit }, bool) {
      // 设置摄像头可关闭
      commit('SET_CAMERASTOP', bool)
    },
    setMicStop({ commit }, bool) {
      commit('SET_MICSOTP', bool)
    },
    setMicList({ commit }, arr) {
      commit('SET_MICDEVLIST', arr)
    },
    // 设置麦克风
    SetCurMicro({ commit }, cur) {
      commit('SET_CURRENTMIC', cur)
    },
    setSpkList({ commit }, arr) {
      commit('SET_SPKDEVLIST', arr)
    },
    // 设置扬声器
    SetCurSpeaker({ commit }, cur) {
      commit('SET_CURRENTSPK', cur)
    },
    setCamList({ commit }, arr) {
      commit('SET_CAMDEVLIST', arr)
    },
    // 设置摄像头
    SetCurCamera({ commit }, cur) {
      commit('SET_CURRENTCAM', cur)
    },
    // 设置分辨率
    SetResolution({ commit }, cur) {
      commit('SET_RESOLUTION', cur)
    },
    setResolutionList({ commit }, arr) {
      commit('SET_RESOLUTION_LIST', arr)
    }
  }
}

export default devices
