import storage from 'store'
const medias = {
  state: {
    mediaLayout: 0, // 1 画中画，2 焦点
    layoutScreenfull: false, // 是否布局全屏显示
    browserScreenfull: false, // 浏览器全屏显示
    mediasMap: {
      VIDEO: [],
      AUDIO: [],
      MEDIA_SHARE: [],
      SCREEN_SHARE: [],
      WHITE_BOARD: []
    }, //  媒体集合
    isMuted: false, // 是否被静音
    switchData: null, // 切换的共享数据
    mediaState: {},
    boardList: [], // 当前用户创建的白板
    currentShareMediaId: null, // 显示当前用户共享的白板ID
    boardNumber: 1, // 当前用户创建的白板数量
    boardMedia: null, // 当前渲染的白板对象
    shareSelfScreen: false, // 是否在共享当前桌面,
    hstAudioRef: null,
    maxVideo: undefined, // 双击后将小视频最大化
    cloudRecordStatus: false, // 云录制状态
  },
  mutations: {
    SET_RECORD_STATUS (state, record) {
      let { status, type } = record
      if (type === 1) { // 云录制
        state.cloudRecordStatus = status
      }
    },
    SET_CURRENT_BOARD: (state, board) => {
      state.boardMedia = board
    },
    // 添加白板，当前用户创建
    SET_BOARD: (state, board) => {
      state.boardList.push(board)
    },
    SET_NUM: state => {
      state.boardNumber = state.boardNumber + 1
    },
    // 设置当前的媒体ID
    SET_CURRENT_SHARE_MEDIA_ID: (state, id) => {
      state.currentShareMediaId = id
    },
    SET_LAYOUT: (state, type) => {
      // 设置布局样式
      state.mediaLayout = type
      storage.set('mediaLayout', type)
    },
    TOGGLE_LAYOUTSCREENFULL: state => {
      // 布局按钮切换全屏
      state.layoutScreenfull = !state.layoutScreenfull
    },
    TOGGLE_BROWSERSCREENFULL: (state, bool) => {
      // 浏览器切换全屏
      state.browserScreenfull = bool
    },
    TOGGLE_MUTED: (state, bool) => {
      // 切换静音
      state.isMuted = bool
    },
    SET_MEDIAS: (state, medias) => {
      // 设置媒体数据
      state.mediasMap = medias
    },
    SET_SWITCHDATA: (state, media) => {
      // 设置切换媒体
      state.switchData = media
    },
    ADD_VIDEOMAP: (state, video) => {
      // 增加媒体流
      state.medias.push(video)
    },
    DEL_VIDEOMAP: (state, index) => {
      // 删除媒体流
      state.medias.splice(index, 1)
    },
    UPDATE_VIDEOMAP: (state, index, video) => {
      // 修改媒体流
      state.medias.splice(index, 1, video)
    },
    EMPTY_VIDEOMPA: state => {
      // 重置媒体
      state.mediasMap = {
        VIDEO: [],
        AUDIO: [],
        MEDIA_SHARE: [],
        SCREEN_SHARE: [],
        WHITE_BOARD: []
      }
    },
    SET_MEDIASTATE: (state, mediaState) => {
      // 添加媒体数据统计
      // state.mediaState[mediaState.userId] = mediaState
      state.mediaState = Object.assign({}, { [mediaState.userId]: mediaState })
    },
    DEL_MEDIASTATE: (state, key) => {
      // 删除媒体数据统计
      Object.keys(state.mediaState).map(item => {
        if (item === key) {
          delete state.mediaState[key]
        }
      })
      // state.mediaState.delete(key)
    },
    SET_SHARE_SELF_SCREEN: (state, status) => {
      state.shareSelfScreen = status
    },
    SET_HST_AUDIO_REF(state, ref) {
      state.hstAudioRef = ref
    },
    SET_MAX_VIDEO(state, video) {
      state.maxVideo = video
    },
  },
  actions: {
    setBoardList({ commit }, data) {
      commit('SET_BOARD', data)
      commit('SET_NUM')
    },
    // 设置布局
    setLayout({ commit }, layout) {
      commit('SET_LAYOUT', layout)
    },
    toggleLayoutScreenfull({ commit }) {
      // console.log('布局切换全屏.')
      commit('TOGGLE_LAYOUTSCREENFULL')
    },
    toggleBrowserScreenfull({ commit }, data) {
      // console.log('浏览器f11切换全屏')
      commit('TOGGLE_BROWSERSCREENFULL', data)
    },
    toggleMuted({ commit }, bool) {
      commit('TOGGLE_MUTED', bool)
    },
    setMedias({ commit }, medias) {
      commit('SET_MEDIAS', medias)
    },
    setSwitchData({ commit }, data) {
      commit('SET_SWITCHDATA', data)
    },
    // 增加媒体数据
    addVideoMap({ commit }, video) {
      commit('ADD_VIDEOMAP', video)
    },
    // 删除媒体数据
    deVideoMap({ commit }, index) {
      commit('DEL_VIDEOMAP', index)
    },
    // 修改媒体数据
    updateVideoMap({ commit }, index, video) {
      commit('UPDATE_VIDEOMAP', index, video)
    },
    // 重置媒体数据
    emptyVideoMap({ commit }) {
      commit('EMPTY_VIDEOMPA')
    },
    setMediaState({ commit }, states) {
      // 增加媒体统计数据
      commit('SET_MEDIASTATE', states)
    },
    delMediaSate({ commit }, key) {
      // 删除媒体数据统计
      commit('DEL_MEDIASTATE', key)
    }
  }
}

export default medias
