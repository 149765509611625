export default {
  state: {
    chatShow: false,
    unreadNum: 0,
  },
  mutations: {
    SET_CHAT_SHOW: (state, show) => {
      state.chatShow = show;
    },
    SET_UNREAD_NUM: (state, num) => {
      state.unreadNum = num;
    },
  },
};
