// eslint-disable-next-line

const RouteView = {
  name: 'RouteView',
  render: h => h('router-view')
}

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "home" */ '@/views/index'),
    meta: {
      title: 'menu.home'
    }
  },
  // login
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login'),
    meta: {
      title: 'menu.login'
    }
  },
  // meeting list
  {
    path: '/meeting-list',
    name: 'meetingList',
    component: () => import(/* webpackChunkName: "meetingList" */ '@/views/meeting-list'),
    meta: {
      title: 'menu.meetingList',
    }
  },
  // meeting room
  {
    path: '/meeting',
    name: 'meeting',
    component: () => import(/* webpackChunkName: "meeting" */ '@/views/meeting/Index'),
    meta: {
      title: 'menu.meeting',
      keepAlive: true
    }
  },
  // Exception
  {
    path: '/exception',
    name: 'exception',
    component: RouteView,
    redirect: '/exception/403',
    meta: {
      title: 'menu.exception',
      icon: 'warning'
    },
    children: [
      {
        path: '/exception/403',
        name: 'Exception403',
        component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/403'),
        meta: {
          title: 'menu.exception.not-permission'
        }
      },
      {
        path: '/exception/404',
        name: 'Exception404',
        component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404'),
        meta: {
          title: 'menu.exception.not-find'
        }
      },
      {
        path: '/exception/500',
        name: 'Exception500',
        component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/500'),
        meta: {
          title: 'menu.exception.server-error'
        }
      }
    ]
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }
]
