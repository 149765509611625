/**
 * 防止静态配置文件被删除，兼容处理
 */
let defaultConfig = {
  defaultServer: {
    // 是否应用默认服务器
    apply: true,
    // 协议
    protocol: "https",
    // ip
    ip: "mt.haoshitong.com",
    // 端口
    port: "",
  },
  // 部分文案
  language: {
    // 浏览器标签页title
    title: "GLA Cloud Meeting",
    // 欢迎使用***
    welcome: "GLA Cloud Meeting",
  },
};

if (window.__WEB_APP_CONFIG__) {
  const { defaultServer, language } = window.__WEB_APP_CONFIG__;
  defaultServer && language && (defaultConfig = window.__WEB_APP_CONFIG__);
}

export default defaultConfig;
