<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import { domTitle, setDocumentTitle } from "@/utils/domUtil";
import { i18nRender } from "@/locales";

export default {
  data() {
    return {};
  },
  computed: {
    locale() {
      // 只是为了切换语言时，更新标题
      const { title } = this.$route.meta;
      title && setDocumentTitle(`${i18nRender(title)} - ${domTitle}`);

      return this.$i18n.getLocaleMessage(this.$store.getters.lang).antLocale;
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(to, from) {
        // 如果是被请出会议室，重新返回进入会议室，需要刷新当前页面
        const needReloadPageFrom = ["meetingList", "meeting"];
        const needReloadPageTo = ["login", "meetingList"];
        if (
          to &&
          from &&
          needReloadPageFrom.includes(from.name) &&
          needReloadPageTo.includes(to.name)
        ) {
          window.onbeforeunload = undefined;
          location.reload();
        }
      },
    },
  },
};
</script>
<style lang="less">
@import url("./pass.css");
</style>
