import storage from 'store'
import hstWebEngine from 'hst-rtc-sdk'
import {
  TOGGLE_LAYOUT,
  TOGGLE_COLOR,

  // i18n
  APP_LANGUAGE
} from '@/store/mutation-types'
import { loadLanguageAsync } from '@/locales'

const app = {
  state: {
    hstWebEngine: null, // 核心 web引擎 hstWebEngine
    hstRtcEngine: null, // paas引擎  hstRtcEngine
    loading: false,
    curRoomInfo: null, // 会议信息
    netFeature: {}, //
    orderVideoData: {}, // 视频排序数据
    lang: 'zh-CN', // 语言
    _antLocale: {}
  },
  mutations: {
    [TOGGLE_LAYOUT]: (state, mode) => {
      state.layout = mode
      storage.set(TOGGLE_LAYOUT, mode)
    },
    [TOGGLE_COLOR]: (state, color) => {
      state.color = color
      storage.set(TOGGLE_COLOR, color)
    },
    [APP_LANGUAGE]: (state, lang, antd = {}) => {
      state.lang = lang
      state._antLocale = antd
      storage.set(APP_LANGUAGE, lang)
    },
    DESTORYENGINE: state => {
      state.hstWebEngine = null
    },
    SET_RTCENGINE: (state, engine) => {
      state.hstRtcEngine = engine
    },
    SET_WEBENGINE: (state, engine) => {
      state.hstWebEngine = engine
    },
    SET_NETFEATURE: (state, feature) => {
      state.netFeature = feature
    }
  },
  actions: {
    initWebEngine ({ commit, state }, params) {
      // 初始化web引擎
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        if(state.hstWebEngine) {
          console.log('init hstWebEngine Success again')
          resolve(state.hstWebEngine)
          return
        }
        const hst = new hstWebEngine();
        hst.init(
          params,
          () => {
            commit('SET_WEBENGINE', hst)
            console.log('init hstWebEngine Success')
            resolve(hst)
          },
          err => {
            console.warn('init fail：', err)
            reject(err)
          }
        )
      })
    },
    setRtcEngine ({ commit }, engine) {
      commit('SET_RTCENGINE', engine)
    },
    destoryEngine ({ commit }) {
      commit('DESTORYENGINE')
    },
    setNetfeature ({ commit }, feature) {
      commit('SET_NETFEATURE', feature)
    },
    setLang ({ commit }, lang) {
      return new Promise((resolve, reject) => {
        commit(APP_LANGUAGE, lang)
        loadLanguageAsync(lang)
          .then(() => {
            resolve()
          })
          .catch(e => {
            reject(e)
          })
      })
    }
  }
}

export default app
