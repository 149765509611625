/**
 * 项目默认配置项
 * title -页面标题
 * primaryColor - 默认主题色, 如果修改颜色不生效，请清理 localStorage
 * storageOptions: {} - Vue-ls 插件配置项 (localStorage/sessionStorage)
 *
 */
import appConfig from "@/config/appConfig";

export default {
  layout: 'sidemenu', // nav menu position: `sidemenu` or `topmenu`
  title: appConfig.language.title,
  pwa: false,
  iconfontUrl: '',
  production: process.env.NODE_ENV === 'production' && process.env.VUE_APP_PREVIEW !== 'true'
}
