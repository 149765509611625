import storage from 'store'

const permission = {
  state: {
    role: '', // 角色 此字段暂时无用，用户实时角色根据 DataState, 和 ManageState 判断
    roleAuth: {}, // 角色权限
    serviceAuth: {}, // 产品权限
    roleAuths: {}
  },

  mutations: {
    SET_SERVICEAUTH: (state, auth) => {
      state.serviceAuth = auth
    },
    SET_ROLE: (state, role) => {
      state.role = role
    },
    SET_ROLEAUTH: (state, auth) => {
      state.roleAuth = auth
    },
    SET_ROLEAUTHS: (state, auth) => {
      state.roleAuths = auth
    }
  },

  actions: {
    SetRole ({ commit }, role) {
      // 设置本地用户信息
      commit('SET_ROLE', role)
      storage.set('role', role)
    },
    SetserviceAuth ({ commit }, auth) {
      // 设置产品权限
      commit('SET_SERVICEAUTH', auth)
    },
    SetRoleAuth ({ commit }, auth) {
      // 设置角色权限
      commit('SET_ROLEAUTH', auth)
    },
    SetRoleAuths ({ commit }, auth) {
      // 设置所有角色权限
      commit('SET_ROLEAUTHS', auth)
    }
  }
}

export default permission
