const getters = {
  cloudRecordStatus: state => state.medias.cloudRecordStatus, // 录制状态
  hstWebEngine: state => state.app.hstWebEngine, // Web引擎
  hstRtcEngine: state => state.app.hstRtcEngine, // Rtc引擎
  netFeature: state => state.app.netFeature, // 网络特性
  mediaLayout: state => state.medias.mediaLayout, // 布局样式
  layoutScreenfull: state => state.medias.layoutScreenfull, // 布局按钮切换全屏
  browserScreenfull: state => state.medias.browserScreenfull, // 浏览器f11切换全屏
  isMuted: state => state.medias.isMuted, // 是否被静音
  mediasMap: state => state.medias.mediasMap, // media的集合
  switchData: state => state.medias.switchData, // 切换的共享数据
  mediaState: state => state.medias.mediaState, // 音量统计集合
  boardList: state => state.medias.boardList, // 当前用户创建的白板
  currentShareMediaId: state => state.medias.currentShareMediaId, // 当前创建的白板ID
  boardNumber: state => state.medias.boardNumber, // 当前用创建的白板的数量
  boardMedia: state => state.medias.boardMedia, // 当前渲染的白板对象
  lang: state => state.app.lang,
  preOpenAudio: state => state.devices.preOpenAudio, // 预开启麦克风
  preOpenVideo: state => state.devices.preOpenVideo, // 预开启涉嫌头
  isCameraStop: state => state.devices.isCameraStop, // 摄像头可以关闭
  isMicStop: state => state.devices.isMicStop, // 麦克风是否可以关闭
  currentMic: state => state.devices.currentMic, // 当前麦克风
  currentSpk: state => state.devices.currentSpk, // 当前扬声器
  currentCam: state => state.devices.currentCam, // 当前摄像头
  micDevList: state => state.devices.micDevList, // 麦克风列表
  spkDevList: state => state.devices.spkDevList, // 扬声器列表
  camDevList: state => state.devices.camDevList, // 摄像头列表
  resolutionList: state => state.devices.resolutionList, // 分辨率列表
  currentResolution: state => state.devices.currentResolution, // 分辨率
  localUser: state => state.user.localUser, // 本地用户信息
  localPubVideo: state => state.user.localPubVideo, // 本地广播视频
  videoStatus: state => state.user.videoStatus, // 用户的视频状态
  localPubAudio: state => state.user.localPubAudio, // 本地广播音频
  audioStatus: state => state.user.audioStatus, // 用户的麦克风状态
  groupUserList: state => state.user.groupUserList, // 组成员信息
  role: state => state.permission.role,
  serviceAuth: state => state.permission.serviceAuth,
  roleAuth: state => state.permission.roleAuth, // 当前角色的权限
  roleAuths: state => state.permission.roleAuths, // 所有角色的权限
  chatShow: state => state.chat.chatShow,
  userListShow: state => state.user.userListShow,
  inviteShow: state => state.user.inviteShow,
  userList: state => Object.values(state.user.groupUserList),
  validUserList: state => {
    // 排除掉会控虚拟用户
    const invalidTerminalType = [26];
    const allUsers = Object.values(state.user.groupUserList)
    return allUsers.filter(({ TerminalType }) => !invalidTerminalType.includes(Number(TerminalType)))
  },
}

export default getters
