import Vue from 'vue'
import Router from 'vue-router'
import { constantRouterMap } from '@/config/router.config'
import { setStorage } from '@/utils/util'

// hack router push callback
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}
Vue.use(Router)

let router =  new Router({
  // mode: 'history',
  // base: '/webapp',
  routes: constantRouterMap
})

router.beforeEach((from, to, next) => {
  if (from.path === '/meeting') {
    if (from.query && from.query.userPass !==  undefined) {
      setStorage('userPass', from.query.userPass);
      delete from.query.userPass;
      next({path: '/meeting',query: from.query, replace: true})
    }
  }
  console.log(from)
  next()
})

export default router
