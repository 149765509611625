<template>
  <svg class="icon svg-icon" aria-hidden="true">
    <title>{{ title }}</title>
    <use :xlink:href="name"></use>
  </svg>
</template>
<script>
export default {
  name: "svgIcon",
  props: {
    title: {
      type: String,
    },
    name: {
      type: String,
    },
  },
};
</script>

<style lang="less" scoped>
.svg-icon {
  outline: none;
}
</style>
