/**
 * 根据某一属性值 删除数组
 */
export function removeByValue(arr, attr1, value1, attr2, value2) {
  var index = 0;
  for (var i in arr) {
    if (arr[i][attr1] === value1 && arr[i][attr2] === value2) {
      index = i;
      break;
    }
  }
  arr.splice(index, 1);
}
/**
 * 根据某一属性值 查找替换另一属性值
 */
export function changeByValue(arr, attr1, value1, attr2, value2) {
  var index = 0;
  for (var i in arr) {
    if (arr[i][attr1] === value1) {
      index = i;
      break;
    }
  }
  arr[index][attr2] = value2;
}
/**
 * 对象转成Map()
 */
export function objToStrMap(obj) {
  const strMap = new Map();
  for (const k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }

  return strMap;
}

/**
 * Map() 转成 对象
 */
export function strMapToObj(strMap) {
  const obj = Object.create(null);
  for (const [k, v] of strMap) {
    obj[k] = v;
  }
  return obj;
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent("HTMLEvents");
  event.initEvent("resize", true, true);
  event.eventType = "message";
  window.dispatchEvent(event);
}

/**
 * 获取指定的地址查询参数
 */

export function getUrlQuery(key) {
  const url = location.href;
  const arr = url.split("?"); //
  const newArr = arr[1].split("&"); //
  for (var i = 0; i < newArr.length; i++) {
    const temp = newArr[i].split("=");
    if (temp[0] === key) {
      const search = temp[1];
      return decodeURIComponent(search);
    }
  }
  return "";
}

/**
 * 获取字符串中所有的参数
 * @param {*} str 待解析字符串
 * @returns 参数对象
 */
export const parseQuery = (str) => {
  var objURL = {};
  str.replace(/([^?=&]+)(=([^&]*))?/g, ($0, $1, $2, $3) => {
    objURL[$1] = $3;
  });
  return objURL;
};

export const getRouteQuery = () => {
  const queryStr = window.location.hash.replace(/#\/[a-z0-9_-]+/i, "");
  const queryObj = parseQuery(queryStr);
  let query = {};
  for (const key in queryObj) {
    const value = queryObj[key];
    if (![undefined, null].includes(value)) {
      try {
        const decodeValue = decodeURIComponent(value);
        query[key] = decodeFun(decodeValue);
      } catch {
        query[key] = decodeFun(value);
      }
    }
  }
  return query;
};

/**
 * Listen to DOM events during the bubble phase.
 *
 * @param {DOMEventTarget} target DOM element to register listener on.
 * @param {string} eventType Event type, e.g. 'click' or 'mouseover'.
 * @param {function} callback Callback function.
 * @return {object} Object with a `remove` method.
 */
export const EventListener = {
  listen(target, eventType, callback) {
    if (target.addEventListener) {
      target.addEventListener(eventType, callback, false);
      return {
        remove() {
          target.removeEventListener(eventType, callback, false);
        },
      };
    } else if (target.attachEvent) {
      target.attachEvent("on" + eventType, callback);
      return {
        remove() {
          target.detachEvent("on" + eventType, callback);
        },
      };
    }
  },
};
export function handleScrollHeader(callback) {
  let timer = 0;

  let beforeScrollTop = window.pageYOffset;
  callback = callback || function () {};
  window.addEventListener(
    "scroll",
    (event) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        let direction = "up";
        const afterScrollTop = window.pageYOffset;
        const delta = afterScrollTop - beforeScrollTop;
        if (delta === 0) {
          return false;
        }
        direction = delta > 0 ? "down" : "up";
        callback(direction);
        beforeScrollTop = afterScrollTop;
      }, 50);
    },
    false
  );
}

export function isIE() {
  const bw = window.navigator.userAgent;
  const compare = (s) => bw.indexOf(s) >= 0;
  const ie11 = (() => "ActiveXObject" in window)();
  return compare("MSIE") || ie11;
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = "", timeout = 1500) {
  if (id === "") {
    return;
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id));
  }, timeout);
}
export function scorePassword(pass) {
  let score = 0;
  if (!pass) {
    return score;
  }
  // award every unique letter until 5 repetitions
  const letters = {};
  for (let i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1;
    score += 5.0 / letters[pass[i]];
  }

  // bonus points for mixing it up
  const variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass),
  };

  let variationCount = 0;
  for (var check in variations) {
    variationCount += variations[check] === true ? 1 : 0;
  }
  score += (variationCount - 1) * 10;

  return parseInt(score);
}

/**
 * add zero
 * @param n number
 */
export function addZero(n) {
  return n < 10 ? "0" + n : "" + n;
}

/**
 * user Browser 判断浏览器版本
 * browserr = browserInfo()
 * console.log(browserr.version)
 * console.log(browserr.name + ' ' + browserr.version)
 * @param n number
 */

export function browserInfo() {
  var browser = {
    //            IE浏览器
    msie: false,
    //            谷歌浏览器
    chrome: false,
    //            Edge浏览器
    Edg: false,
    //            火狐浏览器
    firefox: false,
    //            opera浏览器
    opera: false,
    //            safrai浏览器
    safari: false,
    //            正在使用的浏览器的名字
    name: "unknown",
    //            正使用浏览器的版本号
    version: 0,
  };
  var userAgent = window.navigator.userAgent.toLowerCase();
  //      使用正则对用户当前浏览器进行判断
  if (
    /(msie|chrome|Edg|firefox|opera|netscape|rv)\D+(\d[\d.]*)/.test(userAgent)
  ) {
    browser[RegExp.$1] = true;
    browser.name = RegExp.$1;
    browser.version = RegExp.$2;
  } else if (/version\D+(\d[\d.]*).*safari/.test(userAgent)) {
    browser.safari = true;
    browser.name = "safari";
    browser.version = RegExp.$2;
  }
  return browser;
}

/**
 * 判断空对象
 * @param fn number
 * @param delay
 */
export function isEmptyObject(obj) {
  return obj === undefined || JSON.stringify(obj) === "{}";
}

export function isObjectValueEqual(a, b) {
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);
  console.warn(aProps, bProps);
  if (aProps.length !== bProps.length) {
    return false;
  }
  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];

    var propA = a[propName];
    var propB = b[propName];
    if (typeof propA === "object") {
      if (this.isObjectValueEqual(propA, propB)) {
        // return true     这里不能return ,后面的对象还没判断
      } else {
        return false;
      }
    } else if (propA !== propB) {
      return false;
    } else {
    }
  }
  return true;
}

/**
 * 防抖函数
 * @param fn number
 * @param delay
 */

export function debounce(fn, delay) {
  var timeout = null; // 创建一个标记用来存放定时器的返回值
  return function (e) {
    // 每当用户输入的时候把前一个 setTimeout clear 掉
    clearTimeout(timeout);
    // 然后又创建一个新的 setTimeout, 这样就能保证interval 间隔内如果时间持续触发，就不会执行 fn 函数
    timeout = setTimeout(() => {
      fn.apply(this, arguments);
    }, delay);
  };
}

/**
 * 节流函数
 * @param fn number
 * @param delay
 */
export function throttle(callback, delay) {
  let startTime = 0;
  let timer = null;
  return function () {
    const endTime = new Date().getTime();
    clearTimeout(timer);
    if (endTime - startTime > delay) {
      callback();
      startTime = new Date().getTime();
    } else {
      timer = setTimeout(() => {
        callback.apply(this, arguments);
      }, delay);
    }
  };
}

export function throttle2(func, wait, options) {
  var context;
  var args;
  var result;
  var timeout = null;
  var previous = 0;
  var now = new Date().getTime();
  options || (options = {});
  var later = function () {
    previous = options.leading === false ? 0 : now;
    timeout = null;
    result = func.apply(context, args);
    context = args = null;
  };
  return function () {
    if (!previous && options.leading === false) {
      previous = now;
    }
    var remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0) {
      clearTimeout(timeout);
      timeout = null;
      previous = now;
      result = func.apply(context, args);
      context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
}

export const getStorage = (key) => {
  let value = window.sessionStorage.getItem(key)
  try {
    value = JSON.parse(value);
    return value;
  } catch (e) {
    return value;
  }
}
export const setStorage = (key, value) => {
  if (typeof value === 'string') {
    value = value
  } else if (typeof value === 'object') {
    value = JSON.stringify(value)
  } else {
    throw new Error('Invalid value')
  }
  window.sessionStorage.setItem(key, value);
}
export const removeStorage = (key) => {
  window.sessionStorage.removeItem(key);
}
