import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import user from './modules/user' // 用户
import devices from './modules/devices' // 设备
import medias from './modules/medias' // 布局和视频
import permission from './modules/permission' // 权限
import chat from './modules/chat' // 权限
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    user,
    devices,
    medias,
    permission,
    chat
  },
  state: {},
  mutations: {},
  actions: {},
  getters
})
